
*, ::after, ::before {
    box-sizing: border-box;
}

html,
body,
#root {
    height: 100%;
}

body {
    overflow-y: scroll;
}

#root {
    display: flex;
    flex-direction: column;
}

@include media-breakpoint-down(sm) {
    .container-x-sm {
        padding: 0;
    }

    .row-x-sm {
        margin: 0;

        > .row-content-x-sm {
            padding: 0;
        }
    }

    .card {
        &,
        .card-img-top,
        .card-footer {
            border-radius: 0;
        }
    }
}
