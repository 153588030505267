
:root {
    scroll-behavior: initial;
}

.card {
    border: 0;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, .06);

    &,
    .card-img-top,
    .card-footer {
        border-radius: 8px;
    }

    .card-header {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .card-header,
    .card-footer {
        background: white;
    }

    .card-footer {
        border-top: 1px solid rgba(0, 0, 0, .1);
    }
}

.modal {
    z-index: 9999;
}

.modal-content {
    box-shadow: 0 4px 6px rgba(0, 0, 0, .7);

    &,
    .modal-header,
    .modal-footer {
        border: 0;
    }
    
    .modal-header {
        align-items: center;
        padding-bottom: 0;
    }
    
    .modal-footer {
        padding-top: 0;
    }
}
